import { useState, useEffect, React } from 'react';

// components 
import ProjectCard from './smallComponents/ProjectCard'
import CloseButton from './smallComponents/HomeButton';
import About from './About';
import Skills from './smallComponents/Skills'

// assets
import aim from '../assets/aim.png'
import covidmaster from '../assets/covidmaster.png'
import techlabs from '../assets/techlabs.png'

function Projects() {
    const [state, setState] = useState('start');

    const renderAbout = () => {
        setState('about');
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return(
        <>
        { state === 'start' && (
            <section id="projects">    
                <CloseButton/>
                <h3><mark>Projects & Work</mark></h3>
                <div id="project-cards">
                    <ProjectCard projectImage={aim} projectName="AIM"/>
                    <ProjectCard projectImage={covidmaster} projectName="Covid-Master"/>
                    <ProjectCard projectImage={techlabs} projectName="TechLabs"/>
                </div>
                <Skills/>
                <p>Want to know more? Here, <b onClick={() => {renderAbout()}} className="section-link">read a bit about me</b>.</p>
            </section>
        )}
        { state === 'about' && <About/>}
        </>
        
    )
}

export default Projects