import React from 'react';


const Landing = (props) => {

    return(
        <section id="landing">
            <h1>Hi! My name is <span>Paola</span>.</h1>
            <h2>I am a junior developer, based in Berlin.</h2>
        </section>
    )
}

export default Landing