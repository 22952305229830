import { React } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCode } from '@fortawesome/free-regular-svg-icons'
import { faAlignLeft, faBook, faDatabase, faLaptopCode, faSpellCheck, faTools } from '@fortawesome/free-solid-svg-icons'

function Skills() {
    return(
        <>
            <h3><mark>What can I do?</mark></h3>
            <p>In my past experience (personal or professional) I have worked with following programming languages, frameworks and tools:</p>
            <ul>
                <li><FontAwesomeIcon icon={faLaptopCode} />Ruby, Ruby on Rails, <b>JavaScript</b>, <b>ReactJS</b>, <b>Gatsby.js</b>, SAPUI5, TypeScript, Angular 2+, ABAP</li>
                <li><FontAwesomeIcon icon={faFileCode} />HTML, CSS/Sass, Bootstrap, Material UI</li>
                <li><FontAwesomeIcon icon={faAlignLeft} />JSON, XML, APIs (e.g. OData)</li>
                <li><FontAwesomeIcon icon={faDatabase} />SQL (SQLite, PostgreSQL), GraphQL</li>
                <li><FontAwesomeIcon icon={faTools} />git, Github/Bitbucket, Jenkins, Gerrit, Netlify, Heroku</li>
                <li><FontAwesomeIcon icon={faDatabase} />Jira, Asana, Trello</li>
                <li><FontAwesomeIcon icon={faBook} />Notion, Confluence</li>
                <li><FontAwesomeIcon icon={faSpellCheck} />Cypress</li>
            </ul>
            <p>I speak fluently <b>Italian</b>, <b>English</b>, <b>German</b> and <b>Spanish</b>, and have a low-intermediate level in <b>Russian</b> and <b>French</b>.</p>
        </>
    )
}

export default Skills