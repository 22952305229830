import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';

function Sidebar() {
    return(
        <div id="sidebar">
            <a href="https://www.linkedin.com/in/paolabarboglio/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="https://github.com/paolabrb" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
            </a> 
        </div>
    )
}

export default Sidebar