import React from 'react';


function CloseButton() {
    return(
        <div className="other-components">
            <a href="/" className="close">
                &times;
            </a>
        </div>
    )
}

export default CloseButton