import Sidebar from './components/layout/Sidebar';
import Main from './components/layout/Main';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="content">
          <Sidebar/>
          <Main/>
        </div>
      </header>
    </div>
  );
}

export default App;
