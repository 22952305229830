import React from 'react';

import websiteScreen from '../../assets/covidmaster_screen.png'

function CovidMaster(props) {
    return(
        <>
         { props.show && 
         <div className="project-overlay">
            <div className="project-modal">
                <span className="close" onClick={props.handleClose}>&times;</span>
                <h1>Covid Master</h1>
                <p><a href="https://covidmaster.netlify.app/" target="_blank" rel="noopener noreferrer">Covid Master</a> is the final project built for the #codeathome Bootcamp of TechLabs in spring 2020. The given task from the bootcamp team was <i>"How might we communicate the actions each citizen should take to stop the virus from spreading?"</i>. Together with a team, after brainstorming our options, we quickly identified a major issue: information on covid, especially at the time (spring of 2020) was very scattered and difficult to summarize. We decided to create a website to provide users with unified information on Covid, for example on the amount of cases in different German states, an online questionnaire for a quick symptom check and an overview of the basic hygiene rules, as well as the specific state regulation and covid measures.</p>
                <div className="modal-screen">
                    <img src={websiteScreen} alt="Covid-Master"/>
                </div>
                <p>Since the time for this challenge was limited, we set up the website with <b>React</b> and hosted it on Netlify. Following the designs from the colleagues on Figma, we designed the main pages pretty quickly. A major roadblock for the team was how to collect the necessary information from different sources (e.g. number of cases or state regulation), since in many cases no real API is to be found. Lacking time to build a crawler, we settled for creating a mockup and not implementing the "real" functionality - in the landing page, the NRW state can be clicked to display how the site is intended to work.</p>
                <p>This project was my first occurrence of work in a fully remote team - we were able to keep each other motivated even at distance and went on to be the winners of the final #codeathome challenge. With more time on our hands I would have liked to explore more how to connect the Data Science section (the map created by a colleague with Python) with our React app, to display actual up-to-date information, as well as improve altogether the styling and responsiveness.</p>
            </div>
        </div>
     }
    </>   
    )
}

export default CovidMaster