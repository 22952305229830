import React from 'react';

import websiteScreen from '../../assets/aim_screen.png'

function Aim(props) {
    return(
        <>
         { props.show && 
         <div className="project-overlay">
            <div className="project-modal">
                <span className="close" onClick={props.handleClose}>&times;</span>
                <h1>AIM</h1>
                <p><a href="https://aimagency.herokuapp.com/" target="_blank" rel="noopener noreferrer">AIM</a> is the final project build for LeWagon's web development bootcamp at the end of 2019, at the start of my web development learning journey. The challenge was to develop a product basing on an original idea, from the early stages of conceptualization (including defining user personas and different user flows), to design (in our case with Figma), as well as defining a database schema and of course implement backend and frontend.</p>
                <p>AIM is a platform meant to offer influencers an overview of different non profit campaigns to join and connect them with the organizations- to do things that matter, together. The users can sign up and login to a personal profile, join or un-join campaigns and look up their location (via Mapbox API).</p>
                <div className="modal-screen">
                    <img src={websiteScreen} alt="Covid-Master"/>
                </div>
                <p>The application is built with the tech stack learned at LeWagon: <b>Ruby on Rails</b> and <b>Javascript</b>, <b>PostgreSQL</b> for the database and hosting on Heroku. I focused mainly on the backend, by setting up the database, the authentication flows (with Pundit) as well as several of the user flows.</p>
                <p>Major learnings out of the first project in a team was how to work together and keep a structured mindset, as well as communicating at all times openly to ensure smooth sailing for the project. Two weeks was enough to set up the main flows and the frontend. In hindsight, I would have liked to spend more time on making the design smoother and fully responsive - as well as improving the authentication process by sending a confirmation email after registration. A big next step would be to add the chance for organizations to sign up and create campaigns autonomously.</p>
            </div>
        </div>
     }
    </>   
    )
}

export default Aim