import  { useState, React }  from 'react';

import About from '../About';
import Projects from '../Projects';
import Landing from '../Landing';

function Main() {
    const [state, setState] = useState('start')

    const renderAbout = () => {
        setState('about');
    }

    const renderProjects = () => {
        setState('projects');
    }
    
    return(
        <div id="main-sections">
            { state === 'start' && (
                <>
                    <Landing/> 
                    <div id="landing-buttons">
                        <button className="button" onClick={ () => {renderAbout()}}>
                            ABOUT
                        </button>
                        <button className="button skills" onClick={ () => {renderProjects()}}>
                            SKILLS & WORK
                        </button>
                    </div>
                </>
                )
            }
            { state === 'about' && <About/> }
            { state === 'projects' && <Projects/>}
        </div>
    )
}

export default Main