import { useState, React } from 'react';

// components
import Aim from '../projects/Aim'
import CovidMaster from '../projects/CovidMaster'
import TechLabs from '../projects/TechLabs'

function ProjectCard(props) {
    const [state, setState] = useState(false)
    const [project, setProject] = useState(null)
    const renderModal = (project) => {
        setState(true);
        console.log(project)
        if (project === 'AIM') {
            setProject('aim');
        } else if (project === 'Covid-Master') {
            setProject('CM');
        } else if (project === 'TechLabs') {
            setProject('TL');
        }
    }

    const closeModal = () => {
        setState(false);
        setProject(null);
    } 

    return(
        <>
        <div className="project-card" onClick={ () => {renderModal(props.projectName)}}>
            <img src={props.projectImage} alt={props.projectName} rel="noopener noreferrer"/>
        </div>
        {project === 'aim' && <Aim show={state} handleClose={() => {closeModal()}}/>}
        {project === 'CM' && <CovidMaster show={state} handleClose={() => {closeModal()}}/>}
        {project === 'TL' && <TechLabs show={state} handleClose={() => {closeModal()}}/>}
        </>
    )
}

export default ProjectCard