import { useState, useEffect, React } from 'react';

// components
import CloseButton from '../components/smallComponents/HomeButton'
import Projects from './Projects';

// assets
import me from '../assets/Paola.jpg'

function About() {
    const [state, setState] = useState('start');

    const renderProjects = () => {
        setState('projects');
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <>
        { state === 'start'  && (
            <section id="about">
            <CloseButton/>
            <div id="about-content">
                <img src={me} alt="Paola Barboglio"/>
                <div>
                    <h3><mark>hi there - let me introduce myself.</mark></h3>
                    <p>I am a Berlin-based junior fullstack developer. After years of experience in corporate settings as executive assistant, I transitioned into tech and learned to code with two different bootcamps, lots of trial and error and a sane amount of googling.</p>
                    <p>In my professional life, one thing became clear pretty quickly - I like to tackle problems and solve them; I'm a <b>troubleshooter</b>. I use this main skill everyday in my current job, during my free time as volunteer web developer and mentor, as well as whenever something breaks down.</p>
                    <p>Besides coding, I do cook and bake a lot, travel (or plan travels), read or start some new diy project.</p>
                    <p>Want to know more? Here, <b onClick={() => {renderProjects()}} className="section-link">check out my work</b>.</p>
                </div>
            </div>
        </section>
        )}
        { state === 'projects' && <Projects/>}
    </>
    )
}

export default About