import React from 'react';

import websiteScreen from '../../assets/techlabs_screen.png'

function TechLabs(props) {
    return(
        <>
         { props.show && 
         <div className="project-overlay">
            <div className="project-modal">
                <span className="close" onClick={props.handleClose}>&times;</span>
                <h1>TechLabs e.V.</h1>
                <p>In early 2020 I enrolled into TechLabs' remote #codeathome Bootcamp to learn more about React and Node.js. After completing the bootcamp, I joined their website team as <b>web developer</b>. The <a href="https://techlabs.org" target="_blank" rel="noopener noreferrer">TechLabs website</a> is built with <b>Gatsby.js</b>; most of the data is queried via <b>GraphQL</b> from a headless CMS (Contentful), while the deployment is done via Netlify. The <a href="https://github.com/techlabsms/techlabs_new" target="_blank" rel="noopener noreferrer">repository of the website</a> is open source on Github.</p>
                <div className="modal-screen">
                    <img src={websiteScreen} alt="Covid-Master"/>
                </div>
                <p>In my experience at TechLabs I have been working on several different issues and topics - from internationalization of the website, to the creation of new features (e.g. the /talks/all page), as well as the integration of Cypress E2E testing. Apart from pure maintenance work, there are always interesting challenges - for example how to show podcast data on the website without querying traditionally over Contentful (that requires continuous updating from the team). A hint: XML and RSS feeds came to my rescue on this one! Working at TechLabs fuels creativity, offering the chance to try out new technologies and ideas; the whole team cooperates on every feature and exchanges ideas, especially on design and implementation possibilities.</p>
            </div>
        </div>
     }
    </>   
    )
}

export default TechLabs